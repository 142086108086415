<template>
  <b-modal
    id="MailTargetModal"
    title-html=""
    body-class="modalBox"
    content-class="p-5"
    size="xl"
    ok-title = "閉じる"
    ok-variant="secondary"
    :hide-header-close="true"
    :centered="true"
    :ok-only="true"
  >
    <div class="">
      <p class="title mb-0">登録大学 - メール送信先</p>
      <hr class="title">
    </div>

    <div
      v-if="existsInvalid"
      class="existsInvalidWrap my-4 py-2">
      <p class="mt-2 mb-1">・システムからのメールが受信されなかったため、メール送信がブロックされているユーザーがいます。</p>
      <p class="my-1">・ユーザーがログイン時の二段階認証に成功すると、ブロックが解除されます。</p>
    </div>

    <div class="title-message">
      <b-table striped hover
        thead-class="tableHead"
        tbody-tr-class="dataWrap"
        :fields="header"
        :items="userList">
          <template v-slot:cell(status)="row">
            <div>
              {{statusTexts[row.item.status]}}
            </div>
          </template>
          <template v-slot:cell(has_invalid_email_address)="row">
            <div v-if="row.item.has_invalid_email_address">
              不可
            </div>
          </template>
      </b-table>
    </div>
  </b-modal>

</template>
<script>
import CONST_STATUS from '@/constants/appStatus';

export default {
  name: 'MailTargetModal',
  props: {
    userList: {
      default: () => [],
    },
    existsInvalid: {
      default: false,
    },
  },
  data() {
    return {
      statusTexts: CONST_STATUS.APP_STATUS_TEXTS,
      itemList: [],
      header: [
        { key: 'name', label: '大学名', sortable: true },
        { key: 'busyo', label: '部署', sortable: true },
        { key: 'login_id', label: 'メールアドレス', sortable: true },
        { key: 'has_invalid_email_address', label: '送信不可', sortable: true },
      ],
    };
  },
  computed: {
  },
};
</script>

<style>
  .modal-header {
    display: none !important;
  }
  .modal-footer {
    border-width: 0 !important;
  }
</style>

<style scoped>

  .titleMsg {
    font-size: 26px !important;
  }
  .headMsg {
    font-size: 24px !important;
  }
  .privacyPolicy {
    margin-top: 20px;
  }
  .privacyPolicy p{
    font-size: 28px;
    margin:5px;
    display: block;
  }
  .title-message, .privacyPolicy span{
    display: block;
    font-size: 16px;
  }
  .privacyPolicy span{
    margin:10px 0;
  }
  .pl-20{
    padding-left: 20px;
  }

  .existsInvalidWrap {
    background-color: #F3F3F3;
  }
</style>
